import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    theme:{
      // bgColor:'',
      // fontColor:''
    },
    sysConfig:{},
  },
  getters: {
  },
  mutations: {
    updateTheme( state, n ) {
      state.theme = {};
      for( let k in n ){
        state.theme[k] = n[k];//.toString();
      }
    },
    setConfig( state, n ) {
      state.sysConfig = {};
      for( let k in n ){
        state.sysConfig[k] = n[k];//.toString();
      }
    },
  },
  actions: {
  },
  modules: {
  }
})
