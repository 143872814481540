// 导入axios
import axios from 'axios';

// 进行一些全局配置
// 公共路由(网络请求地址)
axios.defaults.baseURL = 'https://gpzj.restful.szqgj.com/';
axios.defaults.baseURL = GlobalConfig.BASE_URL;
// 添加请求拦截器
axios.interceptors.request.use(function (config) {

    

    // 在发送请求之前做些什么
    if (localStorage.getItem('token')) {
        // config.headers = { 'Content-Type': 'application/json' }
        // 如果后台要token这么写：
        config.headers.Authorization = 'Bearer ' + localStorage.getItem('token')
        // 如果后台要Authorization 这么写：config.headers.Authorization = `Bearer ` + localStorage.getItem('token')
    }

  
    return config;
  }, function (error) {
    // 对请求错误做些什么

    if(error.response.data.code!="200"){
        return error.response;
    }
    // return Promise.reject(error);
  });

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么

    return response;
  }, function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    if(error.response.data.code!="200"){

        return error.response;
    }
    // return Promise.reject(error);
  });
// 请求响应超时时间
// axios.defaults.timeout = 5000;
// 封装自己的get/post方法
export default {
    get: function(path = '', data = {}) {
        return new Promise(function(resolve, reject) {
            axios.get(path, {
                params: data
            }).then(function(response) {
                // 按需求来，这里我需要的是response.data，所以返回response.data，一般直接返回response
                resolve(response);
            }).catch(function(error) {
                reject(error);
            });
        });
        
    },
    post: function(path = '', data = {}) {
        return new Promise(function(resolve, reject) {
            axios.post(path, data)
                .then(function(response) {
                    resolve(response);
                })
                .catch(function(error) {
                    reject(error);
                });
        });
    }
};
