
// import {createApp} from 'vue'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import qs from 'qs'
// Vue.use(qs)
// import $ from './assets/js/jquery.min.js'
import jquery from "jquery";
Vue.prototype.$ = jquery;
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
import Api from './api/index.js';
Vue.prototype.$api = Api;
Vue.prototype.$sets=new Vue();

import Site from './assets/js/site.js'
Vue.use(Site);

Vue.config.productionTip = false

//Vant
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)

//前置守卫
router.beforeEach((to, from, next) => {
    //刷新token
    let starttime = localStorage.getItem('nowtime');
    let endtime = new Date().getTime();
    let IsForget = localStorage.getItem('IsForget');
    if(IsForget&&starttime&&((endtime-starttime) > 604800000)){
        var url = axios.defaults.baseURL + 'api/user/replaceToken'
        fetch(url, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + localStorage.getItem('token')
            },
            data:{
                isForget:localStorage.getItem('IsForget')?1:0
            }
        })
        .then(res => {
            return res.json()
        })
        .then(data => {
            //console.log(data)
            if(data.status){
                let token = data.data;
                localStorage.setItem('token',token);
                localStorage.setItem('nowtime',endtime);
                
            }
        });
    }
   //超过一周退出
   if(localStorage.getItem('IsForget')&&starttime&&((endtime-starttime) > 604800000)){ 
        localStorage.clear();
    }
next();
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
